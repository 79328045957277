<template>
    <div id="fit-window" class="d-flex flex-row align-items-center justify-content-center bg-vai-main-grey">
        <div id="welcome-stage" class="rounded">
            <div class="d-flex flex-column force-width-40 align-content-center">
                <img src="@/assets/images/logo/vai_logo_invert_valve_white.png" id="welcome-logo" alt="ValveAI Horizontal Logo">
                <span id="draw-line" class="mt-3"></span>
                <div id="sub-text" class="text-center white-text fs-1-75 text-wrap">
                    Next Generation Cross Connection Control Software
                </div>
                <div class="py-3"></div>
                <div id="sub-text-two" class="text-center orange-txt fs-2 font-weight-bold text-wrap">
                    {{currentGreeting}}<br>
                    <span class="fs-1-5 font-weight-normal mt-2">Welcome To Valve Ai</span>
                </div>
                <div class="py-3"></div>
                <div id="wait-for-all" class="text-center">
                    <b-button 
                        variant="vai-main-orange"
                        size="lg"
                    >
                        Start The Tour
                    </b-button>
                </div>
                <div id="wait-for-all" class="text-center pt-3">
                    <b-button 
                        variant="vai-secondary-darker"
                    >
                        No Thanks
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

export default {
    name: 'NewUserWelcome', // TODO: CHANGE NAME
    components:{

    },
    props:{

    },
    data(){
        return{

        }
    },
    methods:{

    },
    watch:{

    },
    computed:{
        currentGreeting: function(){
            var currentTime = new Date().getHours();
            if(currentTime >= 0 && currentTime < 12){
                //Morning
                return `Good Morning ${this.$store.getters.firstName}`;
            }else if(currentTime >= 12 && currentTime < 20){
                //Afternoon
                return `Good Afternoon ${this.$store.getters.firstName}`;
            }else if(currentTime >= 20 && currentTime <= 23){
                //Night
                return `Good Evening ${this.$store.getters.firstName}`;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
#fit-window{
    height: calc(100vh - 56px);
    width: calc(100vw);
}
.force-width-40{
    width: 40vw;
}
#welcome-logo{
    width: 40vw;
    -webkit-animation: fadelogo 0.5s;
    -moz-animation:    fadelogo 0.5s; 
    -o-animation:      fadelogo 0.5s; 
    animation:         fadelogo 0.5s; 
    animation-fill-mode: forwards;
}
@keyframes fadelogo {
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
#draw-line{
    z-index: 10;
    height:0px;
    width:1px;
    border-bottom: 10px solid #D18329;
    border-radius: 5px 5px 5px 5px;
    
    -webkit-animation: increase 1s;
    -moz-animation:    increase 1s; 
    -o-animation:      increase 1s; 
    animation:         increase 1s; 
    animation-delay: 0.25s;
    animation-fill-mode: forwards;
}
@keyframes increase {
    0%{
        margin-left:10vw;
        margin-right:10vw;
    }
    100% {
        width: 40vw;
    }
}
.orange-txt{
    color:#D18329;
}
.white-text{
    color: white;
}
#sub-text{
    z-index: 5;
    animation: fade-in-text ease-in 250ms;
    -webkit-animation: fade-in-text ease-in 250ms;
    -moz-animation: fade-in-text ease-in 250ms;
    -o-animation: fade-in-text ease-in 250ms;
    -ms-animation: fade-in-text ease-in 250ms;
    animation-delay: 1.25s;
    opacity: 1;
    transform-origin: center center;
    transform: scaleY(0);
    animation-fill-mode: forwards;
}

@keyframes fade-in-text {
    0% {
        transform: translateY(-0.5rem) scaleY(0);
    }
    100% {
        
        transform: translateY(1.25rem) scaleY(1);
    }
}
#sub-text-two{
    z-index: 5;
    animation: get-started linear 250ms;
    -webkit-animation: get-started linear 250ms;
    -moz-animation: get-started linear 250ms;
    -o-animation: get-started linear 250ms;
    -ms-animation: get-started linear 250ms;
    animation-delay: 1.5s;
    opacity: 1;
    transform-origin: center top;
    transform: scaleY(0);
    animation-fill-mode: forwards;
}
@keyframes get-started {
    0% {
        transform: scaleY(0);
    }
    100% {
        
        transform: scaleY(1);
    }
}
#wait-for-all{
    z-index: 5;
    animation: get-started linear 100ms;
    -webkit-animation: get-started linear 100ms;
    -moz-animation: get-started linear 100ms;
    -o-animation: get-started linear 100ms;
    -ms-animation: get-started linear 100ms;
    animation-delay: 1.75s;
    opacity: 1;
    transform-origin: center center;
    transform: scaleY(0);
    animation-fill-mode: forwards;
}
</style>